export const BROKER_ENDPOINT = "broker/info/";
export const BONDS_ENDPOINT = "landing/asset/?asset_type=0";
export const SHARE_ENDPOINT = "landing/asset/?asset_type=1";
export const EUROBONDS_ENDPOINT = "landing/asset/?asset_type=2";
export const CORPORATES_ENDPOINT = "landing/asset/?asset_type=3";
export const QA_BOND_ENDPOINT = "landing/qna/?asset_type=0";
export const QA_STOCK_ENDPOINT = "landing/qna/?asset_type=1";
export const QA_EUROBONDS_ENDPOINT = "landing/qna/?asset_type=2";
export const QA_CORPORATES_ENDPOINT = "landing/qna/?asset_type=3";
export const VIDEOS_ENDPOINT = "landing/video";
export const BOND_ADVANTAGES_ENDPOINT = "landing/advantage/?asset_type=0";
export const STOCK_ADVANTAGES_ENDPOINT = "landing/advantage/?asset_type=1";
export const ADVANTAGES_EUROBONDS_ENDPOINT = "landing/advantage/?asset_type=2";
export const CORPORATES_ADVANTAGES_ENDPOINT = "landing/advantage/?asset_type=3";
export const BLOGS_ENDPOINT = "landing/blog/";
export const BLOG_ENDPOINT = "landing/blog/slug/";
