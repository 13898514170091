import { useQuery } from "react-query";

import { fetchHandler } from "../fetchHandler";
import { useTranslation } from "next-i18next";
import {
  QA_STOCK_ENDPOINT,
  QA_BOND_ENDPOINT,
  QA_EUROBONDS_ENDPOINT,
  QA_CORPORATES_ENDPOINT,
} from "../endpoint_types";
import { BondTypes } from "../../types/bond_types";

export default function useQA({
  bondType = BondTypes.bond_type,
  enabled = true,
} = {}) {
  const optionsByType = {
    [BondTypes.bond_type]: {
      key: "qa_bond",
      to: QA_BOND_ENDPOINT,
    },
    [BondTypes.share_type]: {
      key: "qa_stock",
      to: QA_STOCK_ENDPOINT,
    },
    [BondTypes.eurobond_type]: {
      key: "qa_eurobond",
      to: QA_EUROBONDS_ENDPOINT,
    },
    [BondTypes.corporate_type]: {
      key: "qa_corporate",
      to: QA_CORPORATES_ENDPOINT,
    },
    "": { key: "", to: "" },
  };

  const { key, to } = optionsByType[bondType];

  const {
    i18n: { language },
  } = useTranslation();

  return useQuery(
    [key, language],
    () => fetchHandler({ to, locale: language }),
    { enabled }
  );
}
