import React, { FC, useMemo } from "react";

import { useTranslation } from "react-i18next";
import Link from "next/link";
import { AppLinkBlock } from "../AppLinkBlock";
import useBroker from "../../rq/queries/useBroker";
import { SocialItems } from "./SocialItems";

export const Footer: FC = () => {
  const [t] = useTranslation();
  const {
    data: {
      phone = "",
      bondua_email = "",
      bondua_title_short,
      bondua_title,
      bondua_address,
      bondua_edrpoy,
    } = {},
  } = useBroker();

  const PHONE_TITLE = useMemo(() => {
    if (!phone) return;
    const phoneSchema = [
      { length: 3, endSymbol: " " },
      { length: 3, startSymbol: "(", endSymbol: ")" },
      { length: 3, startSymbol: " " },
      { length: 2, startSymbol: "-" },
      { length: 2, startSymbol: "-" },
    ];

    let count = 0;
    let phoneFormatted = "";
    for (const i of phoneSchema) {
      const { length, endSymbol, startSymbol } = i;

      phoneFormatted += `${startSymbol ? startSymbol : ""}${phone.slice(
        count,
        count + length
      )}${endSymbol ? endSymbol : ""}`;

      count += length;
    }

    return phoneFormatted;
  }, [phone]);

  return (
    <footer className="footer" id="contacts">
      <div className="footer-top">
        <div className="container">
          <div className="footer-top-inner">
            <div className="footer-consultation consultation">
              <Link href="/">
                <a className="footer-brand">
                  <img
                    src="/imgs/logo.svg"
                    className="header-logo"
                    alt="logo"
                  />
                </a>
              </Link>
              <p className="consultation__title title">
                {t("consultation_title")}
              </p>
              {phone && (
                <div className="consultation__phone">
                  <a href={`tel:${phone}`} className="phone">
                    {PHONE_TITLE}
                  </a>
                </div>
              )}
              <p className="consultation__help highlight title-bold">
                {t("consultation_subtitle")}
              </p>
            </div>

            <div className="download-block download">
              <p className="download__title title">{t("download_title")}</p>
              <p className="download__title title">{bondua_title_short}</p>
              {/* <p className="download__title title-bold">{bondua_title}</p> */}
              <p className="download__title title">
                {t("edrpou_text")} {bondua_edrpoy}
              </p>
              <p className="download__title title">{bondua_address}</p>

              {/* <p className="download__subtitle subtitle">
                {t("download_subtitle")}
              </p> */}
              <div className="download__email email">{bondua_email}</div>
              <AppLinkBlock />

              <SocialItems />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-inner">
            <div className="footer-copyright">
              Copyright © 2021
              <Link href={`/`}>
                <a className="header-brand">Bond.ua</a>
              </Link>
              ™. Все права защищены.
            </div>
            <ul className="footer-nav">
              <li>
                <Link href={'/confidential'}>Политика конфиденциальности </Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <style jsx global>{`
        @import "./Footer.scss?ss";
      `}</style>
    </footer>
  );
};
